import style from './index.module.scss'
import template from '@styles/reusable.styles.module.scss';
import { IServiceCards } from '@components/main-page/main-page.state';
import { CardSmall } from '@components/shared/card-types/card-small';

interface IServicesSectionProps {
    title: string;
    subtitle: string
    cards: IServiceCards[];
}


const ServicesSection = ({ title, subtitle, cards }: IServicesSectionProps) => {
    return (
        <section className={`${template.section} ${template.section_light}`}>
            <div className={`${template.container} ${template.container_small} ${style.container}`}>
                <h2 className={`${template.title} ${style.title}`}>
                    {title}
                </h2>
                <div className={`${template.subtitle} ${style.subtitle}`}>
                    {subtitle}
                </div>
            </div>
            <div className={`${template.container} ${template.container_large} ${style.cards}`}>
                {
                    cards.map((item: IServiceCards, index: number) => {
                        return (
                            <div key={index} className={`${style.cards__item}`}>
                                <CardSmall
                                    title={item.title}
                                    iconSrc={item.icon}
                                    width={'60'}
                                    height={'60'}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default ServicesSection;
