import Image from 'next/image';
import style from './index.module.scss'


interface ICardSmallProps {
    iconSrc: string;
    title: string;
    iconAlt?: string;
    width: string;
    height: string;
}

export const CardSmall = ({ title, iconSrc, iconAlt, width, height }:ICardSmallProps) => {
    return(
        <div className={`${style.card}`}>
            <Image loading='lazy' src={iconSrc} alt={iconAlt || title} width={width} height={height}/>
            <div className={`${style.card__title}`}>
                {title}
            </div>
        </div>
    )
}
